<template>
    <dynamic-link id="link" :to="toolData.href" :target="toolData.external ? '_blank' : ''">
        <img :src="this.$config.backend_host + toolData.background_image">
        <div class="tool-content">
            <span class="category">{{ toolData.category }}</span>
            <h3 class="tool-name">{{ toolData.name }}</h3>
            <p class="tool-desc">{{ toolData.description }}</p>
        </div>
    </dynamic-link>
</template>

<script>
import axios from "axios";

export default {
    name: "ToolHighlight",
    props: {
        index: Number
    },
    data() {
        return {
            toolData: {
                name: "Emptyness...",
                description: "Seems like this spot is not filled yet...",
                category: null,

                href: null,
                external: false,

                background_image: "/assets/images/tools/electricity.jpg",
            }
        }
    },
    mounted() {
        axios
                .get(this.$config.backend_host + '/api/highlighted_tools/' + this.index)
                .then(response => {
                    if (Object.keys(response.data).length > 0)
                        this.toolData = response.data;
                })
    }
}
</script>

<style scoped>


a, div {
    display: inline-flex;
    flex-direction: column;
    align-items: center; /* vertical */
    justify-content: center; /* horizontal */
    background-color: transparent;
    min-width: 13em;
    height: 30em;
    max-height: 60vw;
    width: 25vw;
    flex: 1;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}


img {
    height: inherit;
    min-width: 100%;
    max-width: none;
    position: absolute;
    display: block;
    transition: height 100ms;
}

.useful-tools a:hover > img {
    height: 200%;
}

.tool-content {
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
    color: white;
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: initial;
}

.tool-content .tool-name {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.tool-content .category {
    position: absolute;
    top: 1em;
    left: 1em;
    text-transform: uppercase;
    font-size: 10pt;
}
</style>