<template>
    <div id="app" :class="{'is-loading': is_loading}">
        <section ref="banner_section" class="banner">
            <div class="banner-content">
                <img :src="this.$config.backend_host + '/assets/images/logo_nospace.svg'"><br><br>
                <h2 class="banner-title">Strukteon</h2>
                <p>Develop. Sleep. Repeat.</p>
            </div>
            <a class="banner-more" @click="smoothScroll('#aboutme', true)">Scroll down</a>
        </section>

        <section id="aboutme" class="aboutme">
            <div class="container">
                <h2>About me</h2>
                <p>
                    I'm a young guy who started programming in late 2015. Currently I'm attending a high-school in
                    vienna.
                    In my free time I'm developing software and I put much effort into making it the best it can be.
                </p>
                <br>

                <h2>Projects</h2>
                <p>
                    Many of my projects are open-source, and you can find them on <a :href="this.$config.social_media.github"
                                                                                     decorated
                                                                                     target="_blank">my Github</a>.
                    Head over to the
                    <router-link decorated to="/projects">project page</router-link>
                    to download some of them!
                </p>
            </div>
        </section>


        <section class="useful-tools">
            <tool-highlight v-for="n in 4" :key="'useful_tool_' + n" :index="n - 1"/>
        </section>


        <section class="endsection">
            <div class="thanku container">
                <div class="left">
                    <h2>That's it for now!</h2>
                    <p>Thank you for visiting! If you'd like to support me and help me financiate my projects (such as
                        this website), consider supporting me at Patreon!</p>
                </div>
                <div class="right">
                    <b-button :href="this.$config.other_links.patreon" target="_blank" variant="info">Become a patron
                    </b-button>
                    <b-button variant="outline-info" @click="smoothScroll('#contactme')">Contact me</b-button>
                </div>
            </div>
            <div id="contactme" class="contact-form container">
                <h2>Contact me</h2>
                <p>If you'd like to contact me, please <a :href="'mailto:' + this.$config.email_business" decorated>email
                    me</a> or <a :href="this.$config.social_media.discord_server" decorated target="_blank">contact me
                    on discord</a>, my username is
                    {{ this.$config.social_media.discord_username }}. I will usually respond faster on discord opposed
                    to emails.</p>
            </div>
        </section>
    </div>
</template>

<script>
import ToolHighlight from "@/components/pages/home/ToolHighlight";
import {BButton} from "bootstrap-vue";

export default {
    name: "Home",
    components: {
        ToolHighlight,
        BButton
    },
    metaInfo: {
        title: 'Home',
        meta: [
            {property: 'og:title', content: 'Home'},
            {property: 'og:site_name', content: 'strukteon.net'},
            {property: 'og:type', content: 'website'},
            {property: 'og:description', content: 'Welcome to the homepage of strukteon.net.'}
        ]
    },
    data() {
        return {
            banner_visible: null,
            is_loading: true,
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    mounted() {
        this.handleScroll();
        this.is_loading = false;
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
        this.$store.commit("navbar/set_visible", true);
    },
    methods: {
        smoothScroll(query, ignore_navbar = false) {
            this.$utils.smoothScroll(query, ignore_navbar);
        },
        handleScroll(event) {
            const bounds = this.$refs["banner_section"].getBoundingClientRect();
            const old_val = this.banner_visible;
            this.banner_visible = bounds.bottom > 0;
            if (old_val !== this.banner_visible) this.$store.commit("navbar/set_visible", !this.banner_visible);
        },
    }
}
</script>

<style scoped>
.banner {
    margin-top: -56px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background-attachment: fixed;
    /*noinspection CssUnknownTarget*/
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("~@/assets/images/pic001.jpg");
    /*background-image: -webkit-linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("http://web.archive.org/web/20181017215337im_/https://strukteon.net//images/pic001.jpg");*/
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    color: white !important;
    visibility: initial;
    overflow: hidden;
}

.is-loading .banner-title {
    opacity: 0;
}

.is-loading .banner-content img {
    opacity: 0;
}

.banner-content img {
    opacity: 1;
    transition: opacity 500ms;
    transition-delay: 500ms;
}

.banner-title {
    display: inline-block;
    position: relative;
    padding: 0.35em 1em;
    font-size: 2em;
    line-height: 1.75em;
    letter-spacing: 0.225em;
    margin: 0 0 1em 0;
    font-weight: bold;
    text-transform: uppercase;
    transition: opacity 1s;
    opacity: 1;
}

.banner-title:before, .banner-title:after {
    content: '';
    height: 2px;
    background-color: white;
    width: 100%;
    display: block;
    position: absolute;
    box-sizing: border-box;
    transition: width 1s;
    transition-delay: .25s;
}

.is-loading .banner-title:before, .is-loading .banner-title:after {
    width: 0 !important;
}

.banner-title:before {
    top: 0;
    left: 0;
}

.banner-title:after {
    bottom: 0;
    right: 0;
}

.is-loading .banner-content p {
    opacity: 0;
}

.banner-content p {
    transition: opacity 0.5s;
    transition-delay: 1s;
}

.banner-content img {
    width: 4em;
    margin-left: -2px;
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, .8));
    animation: logoanim 3s ease infinite;
}

@media screen and (max-width: 576px) {
    /* disable animations to reduce lag */
    .banner-content img {
        animation: none;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    /*
        .banner {
            background-attachment: unset;
        }
        */
}

@keyframes logoanim {
    0% {
        padding-bottom: 20px;
        padding-top: 0;
    }
    50% {
        padding-top: 20px;
        padding-bottom: 0;
    }
    100% {
        padding-top: 0;
        padding-bottom: 20px;
    }
}

.banner-content {
    font-size: 15pt;
    letter-spacing: 0.225em;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
}

.is-loading .banner-more {
    transform: translateY(8.5em);
}

.banner-more:hover {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.banner-more {
    font-size: 12pt;
    transform: translateY(0);
    border: none;
    bottom: 0;
    color: inherit;
    height: 8.5em;
    left: 50%;
    letter-spacing: 0.225em;
    margin-left: -8.5em;
    opacity: 1;
    outline: 0;
    padding-left: 0.225em;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    width: 16em;
    transition: transform 0.5s;
    transition-delay: 1s;
}

.banner-more::after {
    /*noinspection CssUnknownTarget*/
    background-image: url("~@/assets/images/arrow.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 4em;
    content: '';
    display: block;
    height: 1.5em;
    left: 50%;
    margin: 0 0 0 -0.75em;
    position: absolute;
    width: 1.5em;
}

/* ABOUT ME SECTION */

.aboutme {
    background-color: #21b2a6;
    padding-top: 8em;
    padding-bottom: 8em;
    text-align: center;
    color: rgba(255, 255, 255, .8);
}

.aboutme h2:after {
    content: '';
    box-sizing: border-box;
    display: inline-block;
}

.aboutme h2 {
    display: inline-block;
    position: relative;
    line-height: 1.75em;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.125em;
    padding-bottom: 1em;
    border-bottom: solid 2px;
    border-color: rgba(0, 0, 0, 0.125);
    margin-bottom: 1em;
}

/* TOOL SECTION */

.useful-tools {
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: row;
    flex-wrap: wrap;
}


/* asdasdasdasdasdasd */

/* Essential Links Section */

.endsection {
    padding-top: 8em;
    padding-bottom: 4em;
    color: white;

    background-attachment: fixed;
    /*noinspection CssUnknownTarget*/
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("~@/assets/images/fog.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.endsection .thanku {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.endsection .thanku .left {
    width: 70%;
    flex-grow: 1;
}

.endsection .thanku .left h2 {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.075em;
}

.endsection .right {
    flex-grow: 1;
}

.endsection .thanku .right > * {
    width: 100%;
}

.endsection .thanku .right > * + * {
    margin-top: 1em;
}

.endsection .contact-form {
    margin-top: 8em;
}

.endsection .contact-form h2 {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.075em;
}

/* navbar */

</style>